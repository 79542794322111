import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "../components/Common/Footer";
import { ADMIN_URI } from "config/constants";
import { LinearProgress } from "@material-ui/core";
import { Authentication, Adapter } from "lib/authentication";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { SupportFooter } from "components/Whitelabel/SupportFooter";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errMessage: {
    textAlign: "center",
    color: "red",
  },
}));

export default function SignIn(props: any) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userData, setUserData] = useState();

  function validateNewPasswordForm(): boolean {
    if (newPassword.length > 0 && confirmPassword.length > 0 && newPassword === confirmPassword) {
      return true;
    } else {
      return false;
    }
  }

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  async function login(redirectUrl?: string | null): Promise<string | boolean> {
    let redirectTo: string | boolean = redirectUrl || false;

    const auth = new Authentication(Adapter.COGNITO);
    const userData = await auth.login(username, password);

    console.log("USERDATA:", userData);

    if (userData.newPassword) {
      setShowNewPassword(true);
      setUserData(userData.userData);
      return false;
    } else {
      if (!userData || userData.error) {
        setErrMessage(userData.error.message);
        return false;
      }

      if (userData && userData.token) {
        console.log("USER DATA:", userData);
        props.userHasAuthenticated(true);
        sessionStorage.setItem("token", userData?.accessToken as string);
        sessionStorage.setItem("token_ts", Date.now().toString());
        sessionStorage.setItem("token_exp", userData?.exp);
        sessionStorage.setItem("orgId", userData?.orgId);
        sessionStorage.setItem("username", username);

        const group = userData?.user?.username;
        const redirectBase = window.location.pathname.replace("/login", "/");
        if (group) {
          if (group == "admin-user") {
            props.setIsAdmin(true);
            redirectTo = redirectTo || `${ADMIN_URI}/segments`;
          } else {
            //if (group == "user") {
            props.setOrgId(userData.orgId);
            redirectTo = redirectTo || `${redirectBase}segments`;
          }
        }
      } else {
        setErrMessage("Could not login");
      }
    }

    return redirectTo || false;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setIsLoading(true);

    const redirectUrl = sessionStorage.getItem("redirect");

    let loggedIn: string | boolean = false;
    try {
      loggedIn = await login(redirectUrl);
    } catch (e) {
      console.log(e);
    }

    if (!loggedIn) {
      setIsLoading(false);
    } else {
      sessionStorage.removeItem("redirect");
      window.location.href = String(loggedIn);
      // props.history.push(loggedIn);
    }
  }

  async function passwordReset(e: any) {
    e.preventDefault();
    try {
      const cognitoUser = new CognitoUser(userData);
      const authData = {
        Username: username,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authData);
      cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          const sessionAttributes = { email: username };
          cognitoUser.completeNewPasswordChallenge(newPassword, sessionAttributes, {
            onSuccess: (result) => {
              console.log(result);
              setErrMessage("");
              setShowNewPassword(false);
            },
            onFailure: (err) => console.log(err),
          });
        },
        onSuccess: (result) => console.log(result),
        onFailure: (err) => console.log(err),
      });
    } catch (e) {
      console.log("Error setting new pasword: ", e);
    }
  }

  function renderNewPasswordForm() {
    return (
      <form className={classes.form} noValidate onSubmit={passwordReset}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newPassword"
          type="password"
          label="New Password"
          name="newPassword"
          // autoComplete="newPassword"
          autoFocus
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          id="confirmPassword"
          // autoComplete="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {isLoading && <LinearProgress />}
        <div className={classes.errMessage}>{errMessage ? errMessage : ""}</div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!validateNewPasswordForm()}
        >
          Update
        </Button>
      </form>
    );
  }

  function renderSignInForm() {
    return (
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {isLoading && <LinearProgress />}
        {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
        <div className={classes.errMessage}>{errMessage ? errMessage : ""}</div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!validateForm()}
        >
          Sign In
        </Button>
        {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don"t have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
      </form>
    );
  }
  console.log("PROPS:", props);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <img src="/images/pelmorex_data_logo.png" alt="Pelmorex Data" className={classes.avatar} /> */}
        <img
          src={props.logo.src}
          alt={props.logo.alt || "Pelmorex"}
          style={props.logo.style}
          className={classes.avatar}
        />
        <Typography component="h1" variant="h5">
          Audience Builder
        </Typography>
        {showNewPassword ? renderNewPasswordForm() : renderSignInForm()}
      </div>
      <Box mt={8}>
        {props.org && props.org.isWhiteLabel && <SupportFooter />}
        {(!props.org || !props.org.isWhiteLabel) && <Footer />}
      </Box>
    </Container>
  );
}
