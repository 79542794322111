import { greenhouseTheme, telusTheme } from "theme";
/**
 * Ideally, I would like this to come from organization in dynamodb.
 * This could be added to the admin form and loaded from api on page load.
 */
export const organizations = [
  {
    name: "Telus",
    keyName: "telus",
    logo: {
      src: "/images/logos/telus-resized.png",
      showText: false
    },
    theme: telusTheme,
    themeName: "telus",
  },
  {
    name: "Greenhouse",
    keyName: "greenhouse",
    baseName: "greenhouse",
    domains: ["two.tgh-ap.com"],
    cpm: "$3",
    destinations: {
      "DDP": {
        partnerId: "131331",
      },
      "TTD": {
        partnerId: "0kx1jfz"
      }
    },
    logo: {
      src: "/images/logos/thegreenhouse-logo.png",
      showText: false,
      style: {
        height: "auto",
        maxWidth: "250px",
      }
    },
    theme: greenhouseTheme,
    themeName: "greenhouse",
    isWhiteLabel: true,
  }
]