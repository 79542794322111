export enum Adapter {
  COGNITO = "cognito",
}

/**
 * The pointer of this class is to represent whichever
 * authentication adapter we choose, such as cognito, google,
 * custom database, etc.
 */

export class Authentication {
  public adapter: any;

  constructor (adapter: Adapter = Adapter.COGNITO) {
    try {
      this.loadAdapter(adapter);
    } catch (e) {
      console.log("Authentication Library Import Errror", e);
    }
  }

  async loadAdapter (name: string) {
    this.adapter = require("./adapters/" + name);
  }

  async login (email: string, password: string) {
    return await this.adapter.handleLogin(email, password);
  }

  async logout () {
    return await this.adapter.handleLogout();
  }

  async getUserData () {
    return await this.adapter.handleGetData();
  }

  async getUserInfo (email: string) {
    return await this.adapter.handleGetInfo(email);
  }

  async updateUserData (
    email: string,
    userData: { Name: string; Value: string | undefined }[]
  ) {
    return await this.adapter.handleUpdateData(email, userData);
  }

  async setGroup (email: string, group: string) {
    return await this.adapter.handleGroup(email, group);
  }

  async setUserStatus (email: string, status: string) {
    let saved = null;
    if (status == "0") {
      saved = await this.disableUser(email);
    } else {
      saved = await this.enableUser(email);
    }
    return saved;
  }

  async enableUser (email: string) {
    return await this.adapter.handleEnable(email);
  }

  async disableUser (email: string) {
    return await this.adapter.handleDisable(email);
  }

  async signUp (email: string, password: string | undefined, group?: string) {
    return await this.adapter.handleSignup(email, password, group);
  }

  async setUserPassword (email: string, password: string) {
    return await this.adapter.handlePasswordChange(email, password);
  }

  async deleteUser (email: any) {
    return await this.adapter.handleDelete(email);
  }
}
