import React from "react";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Footer () {
  return (
    <footer style={{ marginBottom: "50px" }}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © " + new Date().getFullYear()}
        {" - "}
        <Link color="inherit" href="https://www.pelmorex.com/" target="_blank">
          Pelmorex Corp
        </Link>{" "}
        All Rights Reserved
      </Typography>
    </footer>
  );
}
