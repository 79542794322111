import { Box, Grid, Link } from "@material-ui/core";
import React, { Fragment, useState } from "react";

export const SupportFooter = () => {
  return (
    <Box mb={2}>
      <Grid alignItems="center" justify="center" container item>
        Need help? Email&nbsp;
        <Link href="mailto:support_ap2@magnetdigital.io">
          <b>support_ap2@magnetdigital.io</b>
        </Link>
        &nbsp;for support
      </Grid>
    </Box>
  );
};
