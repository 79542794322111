import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "../components/Common/Footer";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../theme";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
}));

export default function NotFound () {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src="/images/pelmorex_data_logo.png"
            alt="Pelmorex Data"
            className={classes.avatar}
          />
          <Typography component="h1" variant="h5">
            Page not found
          </Typography>
        </div>
        <Box mt={8}>
          <Footer />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
