const Constants = Object.freeze({
  APP_NAME: "Audience Builder",
  // POI_DATA_CLIENT_KEY: "40051f3c-1060-4a55-b74c-55ff61f3f81c",//"a81ce167-9888-4113-ac26-4fdd828e82e1",
  // POI_DATA_ENDPOINT: "https://poi.data.pelmorex.com", //https://3ko72zvxji.execute-api.us-east-2.amazonaws.com
});

export const ADMIN_URI = "/admin";

// MUST MATCH COGNITO GROUP LABELS
export const ACCESS_GROUPS = {
  ADMIN: "Admin",
  USER: "User",
};
export const ACCESS_LEVELS = {
  ADMIN: 1,
  CLIENT: 9,
};

export default Constants;
