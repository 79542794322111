import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/blueGrey";

// Create a theme instance.
// 009688 primary
// 343a40 banner
const defaultThemeOptions: any = {
  palette: {
    primary: {
      main: "#009688",
    },
    secondary: {
      main: "#009688",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  header: {
    background: "#343a40",
  },
  content: {
    background: "#efefef",
  },
};
export const defaultTheme: any = createTheme(defaultThemeOptions);

const telusThemeOptions: Partial<ThemeOptions | any> = {
  palette: {
    primary: {
      main: "#2B8000",
    },
    secondary: {
      main: "#4c2b6f",
    },
    text: {
      primary: "#2a2c2e",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },

  },
  typography: {
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    h5: {
      color: "#4c2b6f",
      fontWeight: 500
    },
  },
  header: {
    background: "#343a40",
  },
  content: {
    background: "#f2eff4",
  }
};
export const telusTheme: any = createTheme(telusThemeOptions);

const greenhouseThemeOptions: Partial<ThemeOptions | any> = {
  palette: {
    primary: {
      main: "#2E513C",
    },
    secondary: {
      main: "#2E513C",
    },
    text: {
      primary: "rgba(0,0,0,0.70)",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FCF7F2",
    },

  },
  typography: {
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    h5: {
      color: "#2E513C",
      fontWeight: 500
    },
  },
  header: {
    background: "#2E513C",
  },
  content: {
    background: "#FCF7F2",
  }
};
export const greenhouseTheme: any = createTheme(greenhouseThemeOptions);

export default defaultTheme;
