import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUser,
} from "amazon-cognito-identity-js";
import config from "config/aws";
import { ACCESS_GROUPS, ACCESS_LEVELS } from "config/constants";

const userPool = new CognitoUserPool({
  UserPoolId: String(config.COGNITO.userPoolId),
  ClientId: String(config.COGNITO.appClientId),
});

// const serviceProvider = new AWS.CognitoIdentityServiceProvider({
//   region: config.REGION,
// });

export const handleLogin = async (email, password) => {
  const authData = {
    Username: email,
    Password: password,
  };

  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken();
        const accessToken = result.getAccessToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        const jwtToken = idToken.getJwtToken();
        const orgId = idToken.payload["custom:organization_id"];
        const exp = idToken.payload["exp"];
        const id = result.getAccessToken().payload["username"]; //@todo replace with actual id

        let access_level = ACCESS_LEVELS.CLIENT;
        let group_name = "User";
        const groups = result.getAccessToken().payload["cognito:groups"];
        if (groups) {
          group_name = groups.shift().toLowerCase();
          if (group_name === "admin") {
            access_level = ACCESS_LEVELS.ADMIN;
          }
        }
        const user = {
          ...result.getAccessToken().payload,
          id,
          userGroup: {
            name: group_name,
            access_level,
          },
        };

        // console.log("access token + " + result.getAccessToken().getJwtToken());
        // console.log("id token + " + result.getIdToken().getJwtToken());
        // console.log("refresh token + " + result.getRefreshToken().getToken());
        resolve({ token: jwtToken, refreshToken, accessToken, orgId, exp, user });
      },
      onFailure: function (err) {
        console.log("error ", err);
        resolve({ error: err });
      },
      newPasswordRequired: function (userAttributes) {
        // need to notify the login page that new password is required
        // delete userAttributes.email_verified;
        // cognitoUser.completeNewPasswordChallenge(newPassword, sessionUserAttributes, this)
        resolve({ newPassword: true, userData:userData });
      },
    });
  });
};

export const handleSignup = async (email: any, password: any, group?: any) => {
  return Promise.reject("Service Account is no longer available.");
  // const params: AWS.CognitoIdentityServiceProvider.AdminCreateUserRequest = {
  //   UserPoolId: String(config.COGNITO.userPoolId),
  //   Username: String(email),
  //   MessageAction: "SUPPRESS",
  //   UserAttributes: [
  //     { Name: "email", Value: String(email) },
  //     { Name: "email_verified", Value: "true" },
  //   ],
  // };

  // return new Promise((resolve, reject) => {
  //   serviceProvider.adminCreateUser(params, async function (error, data) {
  //     if (error) {
  //       console.log("Error adding user to cognito: " + error);
  //       resolve({ error: error.message });
  //     } else {
  //       console.log("User Data:", data);
  //       /**
  //        * The AWS sdk does not return promises, so we have to
  //        * go through this silly callback stack to perform the
  //        * various tasks on the user account before we resolve
  //        * the initial promise.
  //        */
  //       const baseParams = {
  //         UserPoolId: config.COGNITO.userPoolId,
  //         Username: email,
  //       };
  //       // @ts-ignore
  //       serviceProvider.adminConfirmSignUp(
  //         {
  //           ...(baseParams as any),
  //         },
  //         () =>
  //           serviceProvider.adminAddUserToGroup(
  //             {
  //               ...(baseParams as any),
  //               GroupName: group || "User",
  //             },
  //             () =>
  //               serviceProvider.adminSetUserPassword(
  //                 {
  //                   ...(baseParams as any),
  //                   Permanent: true,
  //                   Password: password,
  //                 },
  //                 () => resolve(data)
  //               )
  //           )
  //       );
  //     }
  //   });
  // });
};

export const handleDelete = async (email) => {
  return Promise.reject("Service Account is no longer available.");
  // const params: AWS.CognitoIdentityServiceProvider.AdminDeleteUserRequest = {
  //   UserPoolId: String(config.COGNITO.userPoolId),
  //   Username: email,
  // };

  // return new Promise((resolve) => {
  //   serviceProvider.adminDeleteUser(params, async function (error, data) {
  //     if (error) {
  //       return resolve({ error: error.message });
  //     }

  //     resolve(data);
  //   });
  // });
};

export const handleGetData = async (token) => {
  const currentUser: CognitoUser | null = userPool.getCurrentUser();
  if (!currentUser) {
    return false;
  }
  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        console.log(err);
        reject(err);
        return;
      }
      if (!session.isValid()) {
        reject(false);
        return false;
      }
      currentUser.getUserData((err, userData) => {
        if (err) {
          console.log(err);
          reject(err);
          return;
        }
        
        let { idToken, accessToken } = session;
        const orgId = idToken.payload["custom:organization_id"];
        const client = idToken.payload["custom:client_name"];
        const exp = idToken.payload["exp"];
        accessToken = session.getAccessToken().getJwtToken() 
        // let access_level = ACCESS_LEVELS.CLIENT;
        // let group_name = "User";
        // const groups = accessToken.payload["cognito:groups"];
        // if (groups) {
        //   group_name = groups.shift().toLowerCase();
        //   if (group_name === "admin") {
        //     access_level = ACCESS_LEVELS.ADMIN;
        //   }
        // }

        resolve({
          // accessLevel: access_level,
          // group: group_name,
          client,
          orgId,
          exp,
          accessToken,
          ...userData,
        });
      });
    });
  });
};

export const handleUpdateData = async (
  email,
  userData: any
) => {
  return Promise.reject("Service Account is no longer available.");
  // const params:
  //   | AWS.CognitoIdentityServiceProvider.AdminUpdateUserAttributesRequest
  //   | any = {
  //     UserPoolId: config.COGNITO.userPoolId,
  //     Username: email,
  //     UserAttributes: userData,
  //   };

  // return new Promise((resolve, reject) => {
  //   serviceProvider.adminUpdateUserAttributes(
  //     params,
  //     async function (error, data) {
  //       if (error) {
  //         return resolve({ error: error.message });
  //       }

  //       resolve(data);
  //     }
  //   );
  // });
};

export const handleGroup = async (email, group) => {
  return Promise.reject("Service Account is no longer available.");
  // const params:
  //   | AWS.CognitoIdentityServiceProvider.AdminAddUserToGroupRequest
  //   | any = {
  //     UserPoolId: config.COGNITO.userPoolId,
  //     Username: email,
  //     GroupName: group,
  //   };

  // return new Promise((resolve, reject) => {
  //   clearGroups(email).then(() => {
  //     serviceProvider.adminAddUserToGroup(params, async function (error, data) {
  //       if (error) {
  //         return resolve({ error: error.message });
  //       }
  //       resolve(data);
  //     });
  //   });
  // });
};

export const handleEnable = async (email) => {
  return Promise.reject("Service Account is no longer available.");
  // const params:
  //   | AWS.CognitoIdentityServiceProvider.AdminEnableUserRequest
  //   | any = {
  //     UserPoolId: config.COGNITO.userPoolId,
  //     Username: email,
  //   };

  // return new Promise((resolve) => {
  //   serviceProvider.adminEnableUser(params, function (error, data) {
  //     if (error) {
  //       return resolve({ error: error.message });
  //     }
  //     resolve(data);
  //   });
  // });
};

export const handleDisable = async (email) => {
  return Promise.reject("Service Account is no longer available.");
  // const params:
  //   | AWS.CognitoIdentityServiceProvider.AdminDisableUserRequest
  //   | any = {
  //     UserPoolId: config.COGNITO.userPoolId,
  //     Username: email,
  //   };

  // return new Promise((resolve) => {
  //   serviceProvider.adminDisableUser(params, function (error, data) {
  //     if (error) {
  //       return resolve({ error: error.message });
  //     }
  //     resolve(data);
  //   });
  // });
};

export const handlePasswordChange = async (email, password) => {
  return Promise.reject("Service Account is no longer available.");
  // const params: AWS.CognitoIdentityServiceProvider.AdminSetUserPasswordRequest =
  //   {
  //     UserPoolId: String(config.COGNITO.userPoolId),
  //     Username: email,
  //     Permanent: true,
  //     Password: password,
  //   };

  // return new Promise((resolve) => {
  //   serviceProvider.adminSetUserPassword(params, function (error, data) {
  //     if (error) {
  //       return resolve({ error: error.message });
  //     }
  //     resolve(data);
  //   });
  // });
};

export const handleGetInfo = async (email) => {
  return Promise.reject("Service Account is no longer available.");
  // const params: AWS.CognitoIdentityServiceProvider.AdminGetUserRequest = {
  //   UserPoolId: String(config.COGNITO.userPoolId),
  //   Username: email,
  // };

  // return new Promise((resolve) => {
  //   serviceProvider.adminGetUser(params, function (error, data) {
  //     if (error) {
  //       return resolve({ error: error.message });
  //     }
  //     resolve(data);
  //   });
  // });
};

export const clearGroups = async (email) => {
  return Promise.reject("Service Account is no longer available.");
  // return new Promise((resolve, reject) => {
  //   const removeCalls = Object.values(ACCESS_GROUPS).map(
  //     (groupName) =>
  //       new Promise((resolveRemove) => {
  //         const removeParams:
  //           | AWS.CognitoIdentityServiceProvider.AdminRemoveUserFromGroupRequest
  //           | any = {
  //             UserPoolId: config.COGNITO.userPoolId,
  //             Username: email,
  //             GroupName: groupName,
  //           };
  //         serviceProvider.adminRemoveUserFromGroup(removeParams, () =>
  //           resolveRemove(true)
  //         );
  //       })
  //   );
  //   Promise.all(removeCalls).then(() => {
  //     resolve(true);
  //   });
  // });
};

export const handleLogout = async () => {
  const currentUser: any = userPool.getCurrentUser();
  if (!currentUser) {
    return false;
  }
  return currentUser.signOut();
};
