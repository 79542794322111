import { useSnackbar, VariantType } from "notistack";

export const useMessage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showError = (error: string) => {
    showMessage(error, "error");
  };

  const showWarning = (warning: string) => {
    showMessage(warning, "warning");
  };

  const showInfo = (info: string) => {
    showMessage(info, "info");
  };

  const showSuccess = (success: string) => {
    showMessage(success, "success");
  };

  const showMessage = (message: string, variant: VariantType = "default") => {
    enqueueSnackbar(message, { variant });
  };

  return {
    showError,
    showWarning,
    showInfo,
    showSuccess,
    showMessage,
  };
};
