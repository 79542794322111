export const SEGMENT_STATUS = Object.freeze({
  SUBMITTED: "Submitted",
  PROCESSING: "Processing",
  SENT: "Sent",
  INCOMPLETE: "Incomplete",
  ARCHIVED: "Archived",
  DRAFT: "Draft",
});

export const SEGMENT_DESTINATION = [
  { id: "DDP", name: "DV360" },
  { id: "TTD", name: "The Trade Desk" },
];

export const ORGANIZATION_THEMES = [
  "telus", "greenhouse"
];
