import React, { useState, useEffect, Fragment } from "react";
import { withRouter, Route } from "react-router-dom";
import Routes from "./routes";
import API from "./api/index";
import Manager from "./api/manager";
import "./styles/app.scss";
import { Adapter, Authentication } from "lib/authentication";
import { useMessage } from "hooks/message";
import _isEmpty from "lodash/isEmpty";
import { defaultTheme, greenhouseTheme, telusTheme } from "theme";
import { ThemeProvider } from "@material-ui/core/styles";
import DatabaseTable from "lib/database";
import AWS from "config/aws";
import { ORGANIZATION_THEMES } from "config/segments";
import { organizations } from "config/organizations";

function App(): any {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isIFrame, setIsIFrame] = useState(false);
  const [orgId, setOrgId] = useState("" as any);
  const [orgName, setOrgName] = useState("" as any);
  const [org, setOrg] = useState({} as any);
  const [theme, setTheme] = useState();
  const [logo, setLogo] = useState({} as any);
  const { showInfo } = useMessage();

  useEffect(() => {
    onLoad();
  }, []);

  // const getToken = async (email: string, orgId: string) => {
  //   const manage = new Manager();
  //   return await manage.authToken(email, orgId);
  // };

  // const getOrganization = async (orgId: string) => {
  //   const db = new DatabaseTable(AWS.DATABASE_TABLES.organizations);
  //   let result: any = null;
  //   try {
  //     result = await db.readItem(orgId);
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   return result;
  // };

  // const setToken = async (userData: { Username: string; orgId: string }) => {
  //   let token = sessionStorage.getItem("token");
  //   if (_isEmpty(token) || token == "null") {
  //     try {
  //       token = await getToken(userData.Username, userData.orgId);
  //     } catch (e) {
  //       console.error("TOKEN ERROR:", e);
  //     }
  //     if (token) {
  //       sessionStorage.setItem("token", token as string);
  //       sessionStorage.setItem("token_ts", Date.now().toString());
  //     }
  //   }

  //   if (_isEmpty(token) || token == "null") {
  //     showInfo("Could not get backend token. Segments can be created but won't be pushed to backend.");
  //   }
  // };

  const loadTheme = (name: string, isDomainBased = false): void => {
    // this really should be a type or interface, will add later if I can
    let themeProps: any = { theme: defaultTheme };
    let logoProps: any = { src: "/images/logo.png", showText: true, style: { height: "48px", maxWidth: "auto" } };
    let orgProps: any = { baseName: "/", isWhiteLabel: false };

    if (organizations && organizations.length > 0) {
      organizations.map((organization) => {
        if (organization.keyName === name) {
          orgProps = {...orgProps, ...organization};
          if (isDomainBased) {
            orgProps.baseName = "/";
          }
          logoProps = {...logoProps, ...organization.logo};
          themeProps.theme = organization.theme;
        }
      });
    }
    
    setOrg(orgProps);
    setLogo(logoProps);
    setTheme(themeProps.theme);
  };

  async function onLoad() {
    let orgThemeName = "default";
    let isDomainBased = false;
    // const iframeEnabled = sessionStorage.getItem("iframeEnabled");
    const authCode = sessionStorage.getItem("authCode");
    if (authCode) {
      // @todo - SAML integration, check idp
      userHasAuthenticated(true);
      setIsIFrame(true);
      // setOrgId("5001");
      // await setToken({} as any);
    } else {
      try {
        const auth = new Authentication(Adapter.COGNITO);
        const userData = await auth.getUserData();
        if (userData) {
          sessionStorage.setItem("token", userData?.accessToken as string);
          sessionStorage.setItem("token_ts", Date.now().toString());
          sessionStorage.setItem("token_exp", userData?.exp);
          sessionStorage.setItem("orgId", userData?.orgId);
          userHasAuthenticated(true);
          if(userData.Username == 'admin-user') { //(userData.accessLevel === 1)
            setIsAdmin(true);
          }
          if (userData.orgId) {
            setOrgId(userData.orgId);
            if (userData.client && ORGANIZATION_THEMES.includes(userData.client)) {
              // may conflict with the domain check below
              orgThemeName = userData.client;
            }
          }
        }
      } catch (e) {
        if (e !== "No current user") {
          // console.log(e);
        }
      }
    }
    if (!orgThemeName || orgThemeName === "default") {
      const path = window.location.pathname;
      const host = window.location.hostname;
      if (organizations && organizations.length) {
        organizations.map((organization) => {
          if (organization.baseName && path.indexOf(organization.baseName) !== -1) {
            orgThemeName = organization.themeName;
            return;
          }
          else if(organization.domains && organization.domains.length) {
            organization.domains.map((domain) => {
              if (host.indexOf(domain) !== -1) {
                orgThemeName = organization.themeName;
                isDomainBased = true;
                return;
              }
            })
          }
        })
      }
    }
    loadTheme(orgThemeName, isDomainBased);
    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Route path="/api" component={API} render={() => <API />} />
          <Routes
            appProps={{
              isAuthenticated,
              userHasAuthenticated,
              isAdmin,
              setIsAdmin,
              orgId,
              setOrgId,
              orgName,
              isIFrame,
              setIsIFrame,
              logo,
              org
            }}
          />
        </ThemeProvider>
      </Fragment>
    )
  );
}

export default withRouter(App);
