const dev = Object.freeze({
  REGION: "us-east-2",
  DYNAMODB_TABLE: "audience-builder-dev",
  LAMBDA_EMAIL_FUNCTION: "segment-dev-send-email",
  COGNITO: {
    CUSTOM_ATTRIBUTE_CLIENT_ID: "orgId",
    userPoolId: "us-east-2_3qMtoeGMR", //"us-east-2_Lda3BoMl5",
    appClientId: "1b2hmt40k0esj7ptv1neel4a7g", //"3h9n5qk3tnhtcnslck9pa861hp",
  },
  DATABASE_TABLES: {
    default: "audience-builder-dev",
    organizations: "audience-builder-organizations-dev",
    users: "audience-builder-users-dev",
  },
  SEGMENT_API_URL: "https://audience-dev.data.pelmorex.com/api",
  POI_CLIENT_KEY: "a81ce167-9888-4113-ac26-4fdd828e82e1",
  POI_ENDPOINT:"https://poi-dev.data.pelmorex.com",
});

const prod = Object.freeze({
  REGION: "us-east-2",
  DYNAMODB_TABLE: "audience-builder",
  LAMBDA_EMAIL_FUNCTION: "segment-send-email",
  COGNITO: {
    CUSTOM_ATTRIBUTE_CLIENT_ID: "orgId",
    userPoolId: "us-east-2_ex7rPAWwO",
    appClientId: "7dnlu18u5bikcn7ac76jaqrlv1",
  },
  DATABASE_TABLES: {
    default: "audience-builder",
    organizations: "audience-builder-organizations",
    users: "audience-builder-users",
  },
  SEGMENT_API_URL: "https://audience.data.pelmorex.com/api",
  POI_CLIENT_KEY: "40051f3c-1060-4a55-b74c-55ff61f3f81c",
  POI_ENDPOINT:"https://poi.data.pelmorex.com"
});

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
  ...config,
};
