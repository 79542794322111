import { ADMIN_URI } from "config/constants";
import React, { Suspense, lazy, Fragment } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import Login from "./pages/Login";
import Logout from "./pages/Logout";
import NotFound from "./pages/NotFound";
import { BrowserRouter as Router } from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const SegmentListOld = lazy(() => import("./pages/SegmentsOld/SegmentList"));
const SegmentOld = lazy(() => import("./pages/SegmentsOld/Segment"));

const SegmentList = lazy(() => import("./pages/Segments/SegmentList"));
const Segment = lazy(() => import("./pages/Segments/Segment"));

const UploadList = lazy(() => import("./pages/Uploads/UploadList"));
const Upload = lazy(() => import("./pages/Uploads/Upload"));

const AdminHome = lazy(() => import("./pages/Admin/Home"));
const AdminOrganizationList = lazy(() => import("./pages/Admin/Organizations/OrganizationList"));
const AdminOrganization = lazy(() => import("./pages/Admin/Organizations/Organization"));
const AdminUserList = lazy(() => import("./pages/Admin/Users/UserList"));
const AdminUser = lazy(() => import("./pages/Admin/Users/User"));

type AppProps = {
  isAuthenticated: any;
  userHasAuthenticated: any;
  orgId: string;
  setOrgId?: any;
  orgName?: string;
  isAdmin?: any;
  setIsAdmin?: any;
  history?: any;
  isIFrame?: boolean;
  setIsIFrame?: any;
  logo?: any;
  org?: any;
};
type RouteProps = {
  appProps: AppProps;
};

export default function Routes({ appProps }: RouteProps) {
  const pathname = useLocation().pathname;
  if (pathname === "/api/" || pathname === "/api") {
    return <Fragment></Fragment>;
  }

  const clientPaths = [
    "/greenhouse"
  ];
  const publicRoutes = [
    { path: "/iframe", component: IFrameRoute },
    { path: "/login", component: Login, exact: true },
  ];
  const authRoutes = [
    { path: "/segments", component: SegmentList, exact: true },
    { path: "/segments/create", component: Segment, exact: true },
    { path: "/segments/:id", component: Segment },
    { path: "/segments-old", component: SegmentListOld },
    { path: "/segments-old/create", component: SegmentOld, exact: true },
    { path: "/segments-old/:id", component: SegmentOld, exact: true },
    { path: "/uploads", component: UploadList, exact: true },
    { path: "/uploads/create", component: Upload, exact: true },
  ];
  const adminRoutes = [
    {
      path: ADMIN_URI,
      component: AdminHome,
      routes: [
        { path: `/segments`, component: SegmentList },
        { path: `/segments/create`, component: Segment },
        { path: `/segments/organization/:orgid`, component: SegmentList },
        { path: `/segments/status/:status`, component: SegmentList },
        { path: `/segments/status/:status/:orgid`, component: SegmentList },
        { path: `/segments/:id`, component: Segment },
        { path: `/organizations`, component: AdminOrganizationList },
        { path: `/organizations/create`, component: AdminOrganization },
        { path: `/organizations/:id`, component: AdminOrganization },
        { path: `/users`, component: AdminUserList },
        { path: `/users/list/:orgId`, component: AdminUserList },
        { path: `/users/create`, component: AdminUser },
        { path: `/users/:id`, component: AdminUser },
      ]
    }
  ];

  return (
    <Suspense fallback={<Fragment />}>
      <Switch>
        <PublicRoute path="/iframe" component={IFrameRoute} appProps={appProps} />
        <PublicRoute exact path="/login" component={Login} appProps={appProps} />
        <PublicRoute exact path="/logout" component={Logout} appProps={appProps} />
        <AuthRoute exact path="/" component={HomeRedirect} appProps={appProps} />
        <AuthRoute exact path="/segments" component={SegmentList} appProps={appProps} />
        <AuthRoute path="/segments/:id" component={Segment} appProps={appProps} />

        <Route exact path="/" render={() => <Redirect to="/login" />} />
        
        <Router basename="greenhouse">
          <PublicRoute path="/iframe" component={IFrameRoute} appProps={appProps} />
          <PublicRoute exact path="/login" component={Login} appProps={appProps} />
          <PublicRoute exact path="/logout" component={Logout} appProps={appProps} />
          <AuthRoute exact path="/" component={HomeRedirect} appProps={appProps} />
          <AuthRoute exact path="/segments" component={SegmentList} appProps={appProps} />
          <AuthRoute path="/segments/:id" component={Segment} appProps={appProps} />
        </Router>
        <AdminRoute exact path={`${ADMIN_URI}`} component={AdminHome} appProps={appProps} />
        <AdminRoute exact path={`${ADMIN_URI}/segments`} component={SegmentList} appProps={appProps} />
        <AdminRoute
          exact
          path={`${ADMIN_URI}/segments/organization/:orgid`}
          component={SegmentList}
          appProps={appProps}
        />
        <AdminRoute exact path={`${ADMIN_URI}/segments/status/:status`} component={SegmentList} appProps={appProps} />
        <AdminRoute
          exact
          path={`${ADMIN_URI}/segments/status/:status/:orgid`}
          component={SegmentList}
          appProps={appProps}
        />
        <AdminRoute path={`${ADMIN_URI}/segments/:id`} component={Segment} appProps={appProps} />

        <AdminRoute exact path={`${ADMIN_URI}/organizations`} component={AdminOrganizationList} appProps={appProps} />
        <AdminRoute
          exact
          path={`${ADMIN_URI}/organizations/create`}
          component={AdminOrganization}
          appProps={appProps}
        />
        <AdminRoute path={`${ADMIN_URI}/organizations/:id`} component={AdminOrganization} appProps={appProps} />
        <AdminRoute exact path={`${ADMIN_URI}/users`} component={AdminUserList} appProps={appProps} />
        <AdminRoute path={`${ADMIN_URI}/users/list/:orgId`} component={AdminUserList} appProps={appProps} />
        <AdminRoute exact path={`${ADMIN_URI}/users/create`} component={AdminUser} appProps={appProps} />
        <AdminRoute path={`${ADMIN_URI}/users/:id`} component={AdminUser} appProps={appProps} />

        <Route exact path={ADMIN_URI} render={() => <Redirect to={`${ADMIN_URI}/segments`} />} />

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export function HomeRedirect(props: any) {
  return <Redirect to="/segments" />;
}

export function IFrameRoute(props: any) {
  // @todo - verify user, set session, let app handle the rest
  sessionStorage.clear();
  const params = new URLSearchParams(useLocation().search);
  const authCode = params.get("a");
  // temporary
  if (authCode == "c7dE3iqMCFCI26JDqjviaedBcmLzWDdU") {
    sessionStorage.setItem("iframeEnabled", "1");
    sessionStorage.setItem("authCode", authCode);
    props.userHasAuthenticated(true);
    props.setIsIFrame(true);
    props.setOrgId("5001");
    // props.history.push("/segments");
    console.log("LOGGED IN");
  } else {
    // window.location.href="/login";
  }
  return null;
}

export function AuthRoute({ component: Component, appProps, ...rest }: any) {
  if (sessionStorage.getItem("token_exp") && Date.now() > Number(sessionStorage.getItem("token_exp")) * 1000) {
    appProps.isAuthenticated = false;
  }
  if (!appProps.isAuthenticated) {
    sessionStorage.setItem("redirect", window.location.pathname);
  }

  return (
    <Route
      {...rest}
      render={(props) => (appProps.isAuthenticated ? <Component {...props} {...appProps} /> : <Redirect to="/login" />)}
    />
  );
}

export function AdminRoute({ component: Component, appProps, ...rest }: any) {
  if (!appProps.isAuthenticated || !appProps.isAdmin) {
    sessionStorage.setItem("redirect", window.location.pathname);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        appProps.isAuthenticated && appProps.isAdmin ? <Component {...props} {...appProps} /> : <Redirect to="/login" />
      }
    />
  );
}

export function PublicRoute({ component: Component, appProps, ...rest }: any) {
  if (sessionStorage.getItem("token_exp") && Date.now() > Number(sessionStorage.getItem("token_exp")) * 1000) {
    appProps.isAuthenticated = false;
  }
  return <Route {...rest} render={(props) => <Component {...props} {...appProps} />} />;
}
