import {
  DeleteItemCommand,
  DeleteItemCommandInput,
  GetItemCommand,
  GetItemCommandInput,
  QueryCommand,
  QueryCommandInput,
  ScanCommand,
  ScanCommandInput,
} from "@aws-sdk/client-dynamodb";
import { PutCommand, PutCommandInput, UpdateCommand, UpdateCommandInput } from "@aws-sdk/lib-dynamodb";
import { v4 as uuid } from "uuid";
import { docClient } from "./client";

export async function saveItem(tableName, primaryKey, dataToSave, clientId): Promise<any | unknown> {
  const id = dataToSave.itemId || uuid();
  const createdAt = dataToSave.createdAt || Date.now();
  const params: PutCommandInput = {
    TableName: tableName,
    Item: {
      itemId: id,
      clientId: clientId,
      ...dataToSave,
      createdAt: createdAt,
      updatedAt: Date.now(),
    },
  };
  try {
    const data = await docClient.send(new PutCommand(params));
    return { data, id, createdAt };
  } catch (e: any) {
    console.log("Error", e.stack);
  }
}

export async function readItem(
  tableName: string,
  primaryKey: string,
  itemId?: any,
  clientId?: any
): Promise<any | unknown> {
  const params: GetItemCommandInput = {
    TableName: tableName,
    Key: {},
  };
  if (itemId) {
    params.Key.itemId = itemId;
  }
  if (clientId) {
    params.Key.clientId = clientId;
  }
  try {
    const data = await docClient.send(new GetItemCommand(params));
    return data.Item;
  } catch (e: any) {
    console.log("Error", e.stack);
  }
}

export async function readItemQuery(tableName, primaryKey, itemId): Promise<any | unknown> {
  const params: QueryCommandInput = {
    TableName: tableName,
    KeyConditionExpression: "#itemId = :itemId",
    ExpressionAttributeNames: {
      "#itemId": "itemId",
    },
    ExpressionAttributeValues: {
      ":itemId": itemId,
    },
    // Key: {
    //   "itemId": itemId
    // }
  };
  try {
    const data = await docClient.send(new QueryCommand(params));
    return data.Items.pop();
  } catch (e: any) {
    console.log("Error", e.stack);
  }
}

export async function updateItem(tableName, primaryKey, itemId, dataToUpdate): Promise<any | unknown> {
  const params: UpdateCommandInput = {
    TableName: tableName,
    Key: {
      itemId: itemId,
      data: dataToUpdate,
    },
    UpdateExpression: "set data = :d",
    ExpressionAttributeValues: {
      ":d": "NEW_DATA",
    },
  };
  try {
    const data = await docClient.send(new UpdateCommand(params));
    return data;
  } catch (e: any) {
    console.log("Error", e.stack);
  }
}

export async function deleteItem(
  tableName: string,
  primaryKey: string,
  itemId?: any,
  clientId?: any
): Promise<any | unknown> {
  const params: DeleteItemCommandInput = {
    TableName: tableName,
    Key: {},
  };
  if (itemId) {
    params.Key.itemId = itemId;
  }
  if (clientId) {
    params.Key.clientId = clientId;
  }
  try {
    const data = await docClient.send(new DeleteItemCommand(params));
    return data;
  } catch (e: any) {
    console.log("Error", e.stack);
  }
}

export async function recursiveScan(params): Promise<any> {
  let allData: any = [];

  const scanUntilDone = async (params) => {
    const data = await docClient.send(new ScanCommand(params));
    if (data && data.Items && data.Items.length > 0) {
      allData = [...allData, ...data.Items];
    }

    if (data.LastEvaluatedKey) {
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      return await scanUntilDone(params);
    }
    return data;
  };
  await scanUntilDone(params);

  return { Items: allData };
}

export async function listItems(tableName, clientId): Promise<any> {
  const params: ScanCommandInput = {
    TableName: tableName,
    FilterExpression: "#clientId = :clientId",
    ExpressionAttributeNames: {
      "#clientId": "clientId",
    },
    ExpressionAttributeValues: { ":clientId": clientId },
  };

  const data: any = await recursiveScan(params);
  if (data && data.Items) {
    return { items: data.Items };
  }

  return false;
}

export async function listAllItems(tableName, filter: any = null, operator = "AND"): Promise<any> {
  const params: ScanCommandInput = {
    TableName: tableName,
    // KeyConditionExpression: "CreatedAt = :createdAt",
    ExpressionAttributeNames: {
      "#name": "name",
      "#status": "status",
    },
    ProjectionExpression: "itemId,clientId,orgName,#name,expiration,createdAt,updatedAt,#status",
  };

  if (filter && filter !== null) {
    const expression: string[] = [];
    params.ExpressionAttributeValues = {};

    Object.entries(filter).forEach((value) => {
      const [filterKey, filterValue] = value;

      expression.push(`#${filterKey} = :${filterKey}`);

      if (params.ExpressionAttributeNames) {
        params.ExpressionAttributeNames[`#${filterKey}`] = filterKey;
      }
      if (params.ExpressionAttributeValues) {
        params.ExpressionAttributeValues[`:${filterKey}`] = `${filterValue}` as any;
      }
    });
    params.FilterExpression = expression.join(` ${operator} `);
  }

  const data: any = await recursiveScan(params);
  if (data && data.Items) {
    return [...data.Items];
  }

  return null;
}

export async function countItems(tableName): Promise<number> {
  const params: ScanCommandInput = {
    TableName: tableName,
    ProjectionExpression: "itemId,clientId",
  };
  const data = await recursiveScan(params);

  if (data && data.Items && data.Items.length) {
    return data.Items.length;
  }
  return 0;
}
