import React from "react";
import { Redirect } from "react-router-dom";
import { Adapter, Authentication } from "lib/authentication";

export default function Logout(props: any) {
  try {
    const auth = new Authentication(Adapter.COGNITO);
    auth.logout();
    sessionStorage.clear();
    localStorage.clear();
  } catch (e) {}
  props.userHasAuthenticated(false);
  return <Redirect to="/login" />;
}